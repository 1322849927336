import { styled } from "styled-components";
import { frameworks, jobInfo, languages, mainSkills, TEXT } from "../text";
import { icons, skillsLogos } from '../utils/icons';
import { DocumentArrowDownIcon, MapPinIcon } from '@heroicons/react/24/outline'
import { useState } from "react";

const Container = styled.div.attrs({ className: 'flex flex-col justify-center h-screen w-3/4 mx-auto' })``;
const Title = styled.h2.attrs({ className: 'font-bold text-4xl' })``;
const ContactContainer = styled.div.attrs({ className: 'flex justify-start gap-x-2 mt-2 items-center' })``;
const StyledGithubLogo = styled(icons.github.image).attrs({ className: 'w-8 h-8 rounded-3xl object-cover fill-current hover:fill-[#0072b1] hover:border-[#0072b1]' })``;
const StyledLinkedInLogo = styled(icons.linkedIn.image).attrs({ className: 'w-8 h-8 rounded-3xl object-cover fill-current hover:fill-[#0072b1] hover:border-[#0072b1]' })``;
const ResumeContainer = styled.a.attrs({ className: 'flex items-center border-2 border-current p-1 rounded-lg hover:fill-[#0072b1] hover:border-[#0072b1] hover:text-[#0072b1]' })``;
const ResumeText = styled.p.attrs({ className: 'text-sm' })``;
const StyledDownloadIcon = styled(DocumentArrowDownIcon).attrs({ className: 'w-4 h-4 rounded-3xl object-cover' })``;
const StyledMapPinIcon = styled(MapPinIcon).attrs({ className: 'w-5 h-5 rounded-3xl object-cover' })``;
const BioText = styled.p.attrs({ className: 'text-sm mt-2' })``;
const CodingExperienceText = styled.p.attrs({ className: 'text-sm mt-2' })``;
const SkillsContainer = styled.div.attrs({ className: '' })``;
const TechnicalSkillsTitle = styled.h3.attrs({ className: 'mt-4 font-bold' })``;
const SkillsTextContainer = styled.div.attrs({ className: 'grid grid-cols-3 gap-8' })``;
const SkillsColumn = styled.div.attrs({ className: 'flex flex-col space-y-1 mt-2' })``;
const SkillText = styled.p.attrs({ className: 'text-sm' })``;
const SkillsLogoContainer = styled.div.attrs({ className: 'flex flex-wrap justify-around mt-4 max-w-full' })``;
const JobsTitle = styled.h3.attrs({ className: 'mt-4 font-bold' })``;

export function Experience() {
  const [isActive, setIsActive] = useState<string[]>([]);

  function toggleLogoColor(logoName: string) {
    setIsActive((prevActive) => {
      let activeLogos = [];

      if (prevActive.includes(logoName)) {
        activeLogos = prevActive.filter((name) => name !== logoName)
      } else {
        activeLogos = [...prevActive, logoName]
      }
      return activeLogos
    });
  };

  return (
    <Container id="experience">
      <Title>{TEXT.SkillsTitle}</Title>
      <ContactContainer>
        <a href="https://github.com/quetcodesfire" rel="noreferrer" target="_blank">
          <StyledGithubLogo />
        </a>
        <a href="https://linkedin.com/in/marquetreid" rel="noreferrer" target="_blank">
          <StyledLinkedInLogo />
        </a>
        <ResumeContainer href="https://marquet-reid-portfolio-site.s3.amazonaws.com/Marquet_Reid_Resume.pdf" rel="noreferrer" target="_blank">
          <ResumeText>{TEXT.Resume}</ResumeText>
          <StyledDownloadIcon />
        </ResumeContainer>
        {/* TODO: Look into moving this to the about me section with an emoji icon */}
        <div className='flex items-center'>
          <StyledMapPinIcon />
          <p>{'Maryland'}</p>
        </div>
      </ContactContainer>
      <BioText>{TEXT.Bio}</BioText>
      <CodingExperienceText>{TEXT.CodingExperience}</CodingExperienceText>
      <SkillsContainer>
        <TechnicalSkillsTitle>{TEXT.TechnicalSkills}</TechnicalSkillsTitle>
        <SkillsTextContainer>
          <SkillsColumn>
            {mainSkills.map((skill) => (
              <SkillText key={skill}>{skill}</SkillText>
            ))}
          </SkillsColumn>
          <SkillsColumn>
            {frameworks.map((framework) => (
              <SkillText key={framework}>{framework}</SkillText>
            ))}
          </SkillsColumn>
          <SkillsColumn>
            {languages.map((language) => (
              <SkillText key={language}>{language}</SkillText>
            ))}
          </SkillsColumn>
        </SkillsTextContainer>
      </SkillsContainer>
      <SkillsLogoContainer>
        {skillsLogos.map((logo) => (
          <div
            key={logo.name}
            onClick={() => toggleLogoColor(logo.name)}
            className="group"
          >
            <logo.image
              className={`w-12 h-12 rounded-3xl border-2 p-1 transition-colors
                ${isActive.includes(logo.name) ? 'fill-[var(--active-color)] border-[var(--active-color)]' : 'fill-current border-current'}
                group-hover:fill-[var(--hover-color)] group-hover:border-[var(--hover-color)]`}
              style={{
                '--hover-color': logo.color || 'blue',
                '--active-color': logo.color || 'green'
              } as React.CSSProperties}
            />
          </div>
        ))}
      </SkillsLogoContainer>
      <JobsTitle>{TEXT.Jobs}</JobsTitle>
      {jobInfo.map((job, key) => (
        <div key={key}>
          <h4 className="text-sm font-bold mt-2">{job.role}</h4>
          <p className="text-xs">{job.dates}</p>
          <p className="text-sm mt-2">{job.summary}</p>
        </div>
      ))}
    </Container>
  );
};
