import { styled } from 'styled-components';
import { TEXT } from '../text'
import Logo from '../images/marquet_reid_logo.png'

const Container = styled.nav.attrs({ className: 'fixed flex justify-between top-0 left-0 w-full text-right space-x-2 py-2.5 px-5' })``;
const MarquetReidLogo = styled.img.attrs({ className: 'h-10 w-auto' })``;
const NavItems = styled.div.attrs({ className: 'flex gap-4 items-center', })``;
const NavText = styled.a.attrs({ className: 'text-sm hover:font-bold' })``;

// TODO: Add this back to NavItems when I uncomment the component
// <NavText href="#projects">{TEXT.Projects}</NavText>
export function Navbar() {
  return (
    <Container>
      <a href="#home">
        <MarquetReidLogo src={Logo} alt='logo' />
      </a>
      <NavItems>
        <NavText href="#home">{TEXT.Home}</NavText>
        <NavText href="#about">{TEXT.About}</NavText>
        <NavText href="#experience">{TEXT.Experience}</NavText>
      </NavItems>
    </Container>
  );
}
